<template>
  <section>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <b-card
          class="card-profile border-primary"
        >
          <h3>{{ $t('Kategorija') }}</h3>
          <div class="profile-image p-0">
            <b-avatar
              size="114"
              variant="info"
            >
              <span
                style="font-size: 27px"
              >
                {{ data.kategorija }}
              </span>
            </b-avatar>
          </div>
          <hr class="mb-2">

          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t('Marka') }}
              </h6>
              <h4 class="mb-0">
                {{ data.marka }}
              </h4>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t('Tip') }}
              </h6>
              <h4 class="mb-0">
                {{ data.tip }}
              </h4>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">
                {{ $t('RegistarskiBroj') }}
              </h6>
              <h4 class="mb-0">
                {{ data.regbroj }}
              </h4>
            </div>
          </div>
          <!--/ follower projects rank -->
        </b-card>
      </b-col>
    </b-row>
    <b-row
      class="match-height"
    >
      <b-col
        lg="6"
        md="6"
      >
        <b-card
          class="border-primary"
        >
          <b-card-body :title="$t('Podaci')">
            <b-table-simple
              hover
              small
              responsive
            >
              <b-tbody>
                <b-tr
                  :variant="data.istekao_tehnicki === true ? 'danger' : data.istice_tehnicki === true ? 'warning' : ''"
                >
                  <b-td
                    class="text-left"
                  >
                    {{ $t('DatumTehnickog') }}
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    {{ data.datum_isteka_tehnickog }}
                  </b-td>
                </b-tr>
                <b-tr
                  :variant="data.istekla_registracija === true ? 'danger' : data.istice_registracija === true ? 'warning' : ''"
                >
                  <b-td
                    class="text-left"
                  >
                    {{ $t('DatumRegistracije') }}
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    {{ data.datum_isteka_registracije }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('KMRedovanServis') }}
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    {{ data.km_redovan_servis }}km
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('StanjePutometar') }}
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    {{ data.stanje_putometar }}km
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('DoRedovnogServisa') }}
                  </b-td>
                  <b-td
                    class="text-right"
                  >
                    {{ data.km_redovan_servis - data.stanje_putometar }}km
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-card
          class="border-primary"
        >
          <b-card-body :title="$t('Oprema')">
            <b-table-simple
              striped
              hover
              small
            >
              <b-tbody>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    <b-form-checkbox
                      disabled
                      :checked="data.prva_pomoc"
                    />
                  </b-td>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('PrvaPomoc') }}
                  </b-td>
                  <b-td>
                    {{ data.datum_isteka_prve_pomoci }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    <b-form-checkbox
                      disabled
                      :checked="data.pp_aparat"
                    />
                  </b-td>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('PPAparat') }}
                  </b-td>
                  <b-td>
                    {{ data.datum_isteka_pp_aparata }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    <b-form-checkbox
                      disabled
                      :checked="data.tahograf"
                    />
                  </b-td>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('Tahograf') }} <span v-if="data.vrsta_tahografa !== ''"> - {{ data.vrsta_tahografa }}</span>
                  </b-td>
                  <b-td>
                    {{ data.datum_isteka_tahografa }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    <b-form-checkbox
                      disabled
                      :checked="data.tng"
                    />
                  </b-td>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('TNG') }}
                  </b-td>
                  <b-td>
                    {{ data.datum_atesta_tng }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    <b-form-checkbox
                      disabled
                      :checked="data.sajla_za_vucu"
                    />
                  </b-td>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('SajlaZaVucu') }}
                  </b-td>
                  <b-td>
                    &nbsp;
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td
                    class="text-left"
                  >
                    <b-form-checkbox
                      disabled
                      :checked="data.trougao"
                    />
                  </b-td>
                  <b-td
                    class="text-left"
                  >
                    {{ $t('Trougao') }}
                  </b-td>
                  <b-td>
                    &nbsp;
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BAvatar, BTableSimple, BTr, BTd, BTbody, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BFormCheckbox,
  },
  data() {
    return {
      data: {},
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get(`/instruktori/vozila/prikazi/${this.$route.params.id}`).then(response => {
        this.data = response.data
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
